import request from '@/utils/request'
import qs from 'qs'


// 查询列表
export function OrderDate (id, page,limit,editor,operation,starttime,endtime,) {
  let data = {
    id, page,limit,editor,operation,starttime,endtime,
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/orderstatistics',
    method: 'post',
    data
  })
}

// 查询密码
export function PassWord (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/showoldpwd',
    method: 'post',
    data
  })
}
// 修改密码
export function PassWords (id,password) {
  let data = {
    id,password
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/modifystaffpwd',
    method: 'post',
    data
  })
}
// 员工订单详情
export function Statisticaldetails (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/staff/statisticaldetails',
    method: 'post',
    data
  })
}