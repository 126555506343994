<template>
  <div>
    <!--详情-->
    <div class="articleTitle">{{ article.title }}</div>
    <div class="xs">
      <span class="author">作者：{{ article.editor }}</span
      ><span>期刊：{{ article.name }}</span>
    </div>
    <p class="bt">附件</p>
    <div class="file" @click="downloadAttach">
      <i class="el-icon-paperclip"></i><a>点击下载附件</a>
    </div>
    <p class="bt">摘要</p>
    <div class="articletext">
      {{ article.summary }}
    </div>
    <p class="bt">关键词</p>
    <div class="articletext">
      {{ article.keyword }}
    </div>
    <p class="bt">备注</p>
    <div class="articletext">
      {{ article.remarks }}
    </div>
	<p class="bt">作者评价</p>
	<img v-for="(item,index) in xing"  :src='xingji>index?huangxing:huixing' class="pj">
	<div class="articletext">
	</div>
	 <div class="articletext">
	 <el-button type="danger" @click="back">返回</el-button>
	  </div>
  </div>
</template>

<script>
import { downloadFile } from '@/API/orderstatus'
import { Statisticaldetails } from '../../../../API/UserList'
export default {
  data () {
    return {
      article: {//文章详情数据

      },
	  xing:[{},{},{},{},{}],
	  xingji:2,
	  huangxing:require('../../../../assets/image/setting/xing2.png'),
	  huixing:require('../../../../assets/image/setting/xing1.png')
    }
  },
  methods: {
   //调用详情接口
    handleClick (id) {
      Statisticaldetails(id).then(res => {
        console.log(res);
        if (res.data.code == 0) {
          this.article = res.data.data[0]
		  this.xingji=res.data.data[0].starrating
          console.log(this.article);
        }
      })

    },
    // 更改表头样式
    headeRowClass ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    //点击返回列表页
    back () {
     this.$router.push({path:"/Staff/OrderStatistics",query:{"id":3}})
    },// 下载附件
	  downloadAttach () {
		let Path = this.article.content
		console.log(Path);
		downloadFile(Path)
	 }
  },
  mounted () {
    this.handleClick(this.$route.query.wid)
  }
}

</script>

<style>
.status_title {
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-weight: 500;
  color: #141414;
  border-bottom: 1px solid #e6e6e6;
}

.status_form {
  padding-top: 10px;
}

.to {
  font-size: 14px;
  color: #333;
  margin: 0 5px;
}
.articleTitle {
  height: 80px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 80px;
  text-align: center;
}
.xs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.bt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.xs .author {
  margin-right: 10px;
}
.file {
  width: 50%;
  height: 40px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.file span {
  font-size: 14px;
}
.articletext {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin: 10px;
}
.pj{
	width: 20px;
}
</style>